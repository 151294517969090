import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";
import { Nav } from "../components/common/FrontendNav";
import { Footer } from "../components/Footer";

export async function getStaticProps(context: any) {
  return {
    props: {
      ...(await serverSideTranslations(context.locale)),
    },
  };
}

interface ErrorPage404Props {}
export default function ErrorPage404({}: ErrorPage404Props) {
  return (
    <div>
      <Nav />
      <div className="container container-content">
        Leider konnte keine Seite gefunden weden
      </div>
      <Footer />
    </div>
  );
}
